<template>
  <v-container class="contenedor-tools">
    <v-text-field
      dense
      class="mbx-0 pbx-0"
      v-model="pageRow.value"
      @change="personaliceRows(pageRow.value)"
      label="No. filas"
    ></v-text-field>
    <!-- EDITAR COLUMNAS  -->
    <v-btn
      v-if="editColsBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('editcolsbottom')"
    >
      <v-badge
        :value="infoEditColsBottom"
        class="top z-top"
        :content="editColsBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoEditColsBottom">
          <v-icon class="contenedor-tools__icon">mdi-view-column</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- REESTABLECER COLUMNAS  -->
    <v-btn
      v-if="refreshBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('refreshbottom')"
    >
      <v-badge
        :value="infoRefreshBottom"
        class="top z-top"
        :content="refreshBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoRefreshBottom">
          <v-icon class="contenedor-tools__icon">mdi-refresh</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- EDITAR COLUMNAS  -->
    <v-btn
      v-if="editBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('editbottom')"
    >
      <v-badge
        :value="infoEditBottom"
        class="top z-top"
        :content="editBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoEditBottom">
          <v-icon class="contenedor-tools__icon">mdi-pencil</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- ELIMINAR COLUMNAS  -->
    <v-btn
      v-if="deleteBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('deletebottom')"
    >
      <v-badge
        :value="infoDeleteBottom"
        class="top z-top"
        :content="deleteBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoDeleteBottom">
          <v-icon class="contenedor-tools__icon">mdi-delete</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- EDITAR LA FILA -->
    <v-btn
      v-if="editRowBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('editrowbottom')"
    >
      <v-badge
        :value="infoEditRowBottom"
        class="top z-top"
        :content="editRowBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoEditRowBottom">
          <v-icon class="contenedor-tools__icon">mdi-pencil</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- COPIAR COLUMNAS  -->
    <v-btn
      v-if="copyRowBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('copyrowbottom')"
    >
      <v-badge
        :value="infoCopyRowBottom"
        class="top z-top"
        :content="copyRowBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoCopyRowBottom">
          <v-icon class="contenedor-tools__icon">mdi-content-copy</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- EXPORTAR TABLA  -->
    <v-btn
      v-if="exportBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('exportbottom')"
    >
      <v-badge
        :value="infoExportBottom"
        class="top z-top"
        :content="exportBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoExportBottom">
          <v-icon class="contenedor-tools__icon">mdi-export-variant</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>
    <!-- AGREGAR COLUMNAS NUEVAS -->
    <v-btn
      v-if="addColsBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('addcolsbottom')"
    >
      <v-badge
        :value="infoAddColsBottom"
        class="top z-top"
        :content="addColsBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoAddColsBottom">
          <v-icon class="contenedor-tools__icon"
            >mdi-table-column-plus-before</v-icon
          >
        </v-hover>
      </v-badge>
    </v-btn>

    <!-- ELIMINAR COLUMNAS NUEVAS -->
    <v-btn
      v-if="deleteNewColsBottom"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('deletenewcolsbottom')"
    >
      <v-badge
        :value="infoDeleteNewColsBottom"
        class="top z-top"
        :content="deleteNewColsBottom.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoDeleteNewColsBottom">
          <v-icon class="contenedor-tools__icon"
            >mdi-table-column-remove</v-icon
          >
        </v-hover>
      </v-badge>
    </v-btn>

    <!-- Ordenar las columnas -->
    <v-btn
      v-if="organiceCols"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('organicecols')"
    >
      <v-badge
        :value="infoOrganiceCols"
        class="top z-top"
        :content="organiceCols.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoOrganiceCols">
          <v-icon class="contenedor-tools__icon">mdi-swap-horizontal</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>

    <!-- EDIT LABEL COLS -->
    <v-btn
      v-if="editLabelCols"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('editlabelcols')"
    >
      <v-badge
        :value="infoEditLabelCols"
        class="top z-top"
        :content="editLabelCols.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoEditLabelCols">
          <v-icon class="contenedor-tools__icon">mdi-pencil</v-icon>
        </v-hover>
      </v-badge>
    </v-btn>

    <!-- ASSOCIATE -->
    <v-btn
      v-if="editAssociate"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('editassociate')"
    >
      <v-badge
        :value="infoAssociate"
        class="top z-top"
        :content="editAssociate.badgeText"
        left
        transition="slide-x-transition"
      >
        <v-hover v-model="infoAssociate">
          <v-icon class="contenedor-tools__icon"
            >mdi-relation-one-to-one</v-icon
          >
        </v-hover>
      </v-badge>
    </v-btn>

    <!-- BTN HISTORIAL NUEVAS -->
    <v-btn
      v-if="histBottom"
      v-show="histBottom.show"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('histbottom')"
    >
      <v-icon class="contenedor-tools__icon"> mdi-history </v-icon>
    </v-btn>
    <!-- BTN COLORES -->
    <v-btn
      v-if="colorBottom"
      v-show="colorBottom.show"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="options('colorbottom')"
    >
      <v-icon class="contenedor-tools__icon"> mdi-format-color-fill </v-icon>
    </v-btn>

    <v-container class="contenedor-tools__textoActionBar">
      <h4
        v-for="i in textoActionToolBar.text"
        v-bind:key="textoActionToolBar[i]"
        class="texto_action--Bar"
      >
        {{ i }}
      </h4>
    </v-container>
    <!-- ICONO DE ELIMINAR REGISTROS GUARDADOS  -->
    <v-btn
      v-if="textoActionToolBar.text.length != 0"
      class="contenedor-tools__btn"
      elevation="0"
      small
      @click="$emit('toggle', 'clearText')"
    >
      <v-icon class="contenedor-tools__icon">mdi-close</v-icon>
    </v-btn>

    <!-- ICONO DE NOVEDADES  -->
    <v-container class="lefticons">
      <template>
        <v-badge
          v-if="novedadesIcon"
          v-show="novedadesIcon.show"
          :value="infoNovedadesIcon"
          class="top z-top"
          :content="novedadesIcon.badgeText"
          left
          transition="slide-x-transition"
        >
          <v-hover v-model="infoNovedadesIcon">
            <v-icon
              class="pdg"
              :color="novedadesIcon.color"
              v-if="novedadesIcon"
              >mdi-alert-octagon
            </v-icon>
          </v-hover>
        </v-badge>
      </template>
      <!-- ICONO DE GESTIONES  -->
      <template>
        <v-badge
          v-if="gestionIcon"
          v-show="gestionIcon.show"
          :value="infoGestionIcon"
          class="top z-top"
          :content="gestionIcon.badgeText"
          left
          transition="slide-x-transition"
        >
          <v-hover v-model="infoGestionIcon">
            <v-icon class="pdg" :color="gestionIcon.color" v-if="gestionIcon"
              >mdi-lightbulb-on</v-icon
            >
          </v-hover>
        </v-badge>
      </template>
    </v-container>

    <template v-if="editColumns">
      <v-dialog v-model="editColumns" max-width="70%" min-height="70%">
        <tool-bar-nav-edit-cols
          v-if="editColumns"
          v-on:edit-columns="eventColumns($event)"
          :columnasMostrar="dataColumns.colsShow"
          :materia="dataColumns.materia"
          :periodo="dataColumns.periodo"
          :listaColumnas="dataColumns.colsList"
          :tipo="dataColumns.endpointEdit"
        ></tool-bar-nav-edit-cols>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-if="deleteNewColsAction"
        v-model="deleteNewColsAction"
        max-width="30%"
        min-height="90%"
      >
        <tool-bar-nav-delete-cols
          v-on:delete-newcols="eventosDeleteCols($event)"
          :listaColumnas="deleteNewColsBottom.colsExtra"
          :materia="deleteNewColsBottom.mat"
          :periodo="deleteNewColsBottom.per"
          :data="deleteNewColsBottom.jsonData"
        ></tool-bar-nav-delete-cols>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-if="addNewColsAction"
        v-model="addNewColsAction"
        max-width="50%"
        min-height="70%"
      >
        <tool-bar-nav-add-cols
          v-on:add-newcols="eventosAddCols($event)"
          :materia="addColsBottom.mat"
          :periodo="addColsBottom.per"
        ></tool-bar-nav-add-cols>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-if="isShowHist"
        v-model="isShowHist"
        max-width="20%"
        min-height="70%"
      >
        <popup-historial :historial="histBottom.data"></popup-historial>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-if="isShowColor"
        v-model="isShowColor"
        max-width="30%"
        min-height="70%"
      >
        <v-card>
          <v-container class="centerC">
            <v-color-picker
              v-model="color"
              canvas-width="100"
              canvas-height="100"
            >
            </v-color-picker>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="second" text @click="aplicarCol()">Aplicar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-if="openOrganice">
      <v-dialog v-model="openOrganice" max-width="30%" min-height="90%">
        <tool-bar-nav-organice-cols
          v-on:organice-cols="eventosOrganiceCols($event)"
          :listaColumnas="organiceCols.colsExtra"
          :materia="organiceCols.mat"
          :periodo="organiceCols.per"
        >
        </tool-bar-nav-organice-cols>
      </v-dialog>
    </template>

    <template v-if="openEditLabel">
      <v-dialog v-model="openEditLabel" max-width="30%" min-height="90%">
        <tool-bar-nav-edit-label
          v-on:edit-label="eventosEditLabelCols($event)"
          :listaColumnas="editLabelCols.colsExtra"
          :materia="editLabelCols.mat"
          :periodo="editLabelCols.per"
        >
        </tool-bar-nav-edit-label>
      </v-dialog>
    </template>
    <template v-if="openAssociate">
      <v-dialog v-model="openAssociate" max-width="30%" min-height="90%">
        <tool-bar-nav-associate
          v-on:associate-cols="eventosAssociate($event)"
          :colsExtra="editAssociate.colsExtra"
          :notas="editAssociate.notas"
          :materia="editAssociate.mat"
          :periodo="editAssociate.per"
          :listN="editAssociate.listN"
        >
        </tool-bar-nav-associate>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import PopupHistorial from "./PopupHistorial.vue";
import ToolBarNavEditCols from "./ToolBarNavEditCols.vue";
import ToolBarNavDeleteCols from "./ToolBarNavDeleteCols.vue";
import ToolBarNavAddCols from "./ToolBarNavAddCols.vue";
import ToolBarNavOrganiceCols from "./ToolBarNavOrganiceCols.vue";
import ToolBarNavEditLabel from "./ToolBarNavEditLabel.vue";

import tablesMixin from "../../mixins/tablas";

export default {
  mixins: [tablesMixin],
  components: {
    PopupHistorial,
    ToolBarNavEditCols,
    ToolBarNavDeleteCols,
    ToolBarNavAddCols,
    ToolBarNavOrganiceCols,
    ToolBarNavEditLabel,
    ToolBarNavAssociate: () =>
      import(
        /* webpackChunkName: "ToolBarNavAssociate"  */ "../component/ToolBarNavAssociate.vue"
      ),
  },
  name: "ToolBarNavigation",
  props: {
    addColsBottom: {
      required: false,
      badgeText: String,
      mat: String,
      per: String,
    },

    deleteNewColsBottom: {
      required: false,
      colsExtra: Array,
      mat: String,
      per: String,
      jsonData: Array,
      badgeText: String,
    },

    editColsBottom: {
      required: false,
      badgeText: String,
    },

    refreshBottom: {
      required: false,
      badgeText: String,
    },

    editBottom: {
      required: false,
      badgeText: String,
      tipoEndpoint: String,
    },

    deleteBottom: {
      required: false,
      badgeText: String,
      tipoEndpoint: String,
      endpoint: String,
      atributo: String,
    },

    editRowBottom: {
      required: false,
      badgeText: String,
      tipo: String,
    },

    copyRowBottom: {
      required: false,
      badgeText: String,
    },

    exportBottom: {
      required: false,
      badgeText: String,
      nameDoc: String,
    },

    histBottom: {
      required: false,
      show: Boolean,
      data: Array,
    },

    colorBottom: {
      required: false,
      show: Boolean,
    },

    novedadesIcon: {
      required: false,
      badgeText: String,
      color: String,
    },

    gestionIcon: {
      required: false,
      badgeText: String,
      color: String,
    },

    organiceCols: {
      required: false,
      badgeText: String,
      mat: String,
      per: String,
    },

    editLabelCols: {
      required: false,
      badgeText: String,
      mat: String,
      per: String,
    },

    editAssociate: {
      required: false,
    },

    grid: {
      idGrid: String,
    },

    selectedRow: Object,

    dataColumns: {
      required: false,
      colsShow: Array, // las columnas personalizadas
      colsList: Array, // lista de columnas general
      endpointEdit: String, // endpoint para editar
      materia: String,
      periodo: String,
    },
    textoActionToolBar: {
      text: "",
    },

    pageRow: {
      value: 0,
      type: null,
    },
  },

  watch: {
    textoActionToolBar: {
      handler(value) {
        if (value) {
          this.textoActionToolBar = value;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  data() {
    return {
      editColumns: false,
      infoEditColsBottom: false,
      infoRefreshBottom: false,
      infoEditBottom: false,
      infoDeleteBottom: false,
      infoEditRowBottom: false,
      infoCopyRowBottom: false,
      infoExportBottom: false,
      infoAddColsBottom: false,
      infoDeleteNewColsBottom: false,
      infoOrganiceCols: false,
      infoEditLabelCols: false,
      infoNovedadesIcon: false,
      infoGestionIcon: false,
      infoAssociate: false,
      itemsSelected: [],
      textoAction: "",
      deleteNewColsAction: false,
      addNewColsAction: false,
      isShowHist: false,
      isShowColor: false,
      openOrganice: false,
      openEditLabel: false,
      openAssociate: false,
      color: null,
    };
  },

  methods: {
    personaliceRows(a) {
      this.grid.idGrid["pageSize"] = parseInt(a);
      this.$store.state[this.pageRow.type] = parent(a);
    },

    options(dataOption) {
      switch (dataOption) {
        case "editcolsbottom":
          this.editColumns = true;
          break;
        case "refreshbottom":
          this.refreshColumns();
          break;
        // EDITAR COLS
        case "editbottom":
          break;
        case "deletebottom":
          this.deleteItem(this.selectedRow);
          break;
        case "editrowbottom":
          this.editItem(this.editRowBottom.tipo);
          break;
        case "copyrowbottom":
          this.copyRow();
          break;
        case "exportbottom":
          this.export();
          break;
        case "addcolsbottom":
          this.addNewColsAction = true;
          break;
        case "deletenewcolsbottom":
          this.deleteNewColsAction = true;
          break;
        case "histbottom":
          this.isShowHist = true;
          break;
        case "colorbottom":
          this.isShowColor = true;
          break;
        case "organicecols":
          this.openOrganice = true;
          break;
        case "editlabelcols":
          this.grid.idGrid.inputBoxBlur();
          this.openEditLabel = true;
          break;
        case "editassociate":
          this.grid.idGrid.inputBoxBlur();
          this.openAssociate = true;
          break;
        default:
          break;
      }
    },

    eventColumns(a) {
      this.editColumns = false;
      if (a == "edit") {
        this.$emit("toggle", a);
      }
    },

    refreshColumns() {
      for (var i = 0; i < this.dataColumns.colsList.length; i++) {
        this.dataColumns.colsList[i]["valor"] = true;
      }
      // Descomentar esto
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("restaurar", "SI");
          fd.append("tipo", this.dataColumns.endpointEdit);
          if (this.dataColumns.materia != null) {
            fd.append("materia", this.dataColumns.materia);
            fd.append("periodo", this.dataColumns.periodo);
          }
          fd.append("datos", JSON.stringify(this.dataColumns.colsList)); // cambia pass profesor
          axios.post(host + "/celdas_ocultas", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              this.$emit("toggle", "refresh");
            } else {
              this.$alert(
                "No se pudo realizar la personalización de las columnas"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    deleteItem(data) {
      if (this.grid.idGrid.selectedCount == 0) {
        this.$alert("Debes selecionar un item para realizar esta acción");
      } else {
        this.$confirm({
          auth: true,
          message: `Escribe "borrar" para eliminar ` + data.mensaje,
          button: {
            no: "Cancelar",
            yes: "Aceptar",
          },
          callback: (confirm, password) => {
            if (confirm == true && password.toLowerCase() == "borrar") {
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then((idToken) => {
                  // console.log('token' +idToken);
                  const fd = new FormData();
                  fd.append("token", idToken);
                  fd.append("tipo", this.deleteBottom.tipoEndpoint);
                  fd.append(this.deleteBottom.atributo, data.id);
                  fd.append("forzar", "SI");
                  axios
                    .post(host + this.deleteBottom.endpoint, fd)
                    .then((result) => {
                      if (result.data.text != "OK") {
                        this.$alert(
                          "Error! no tienes permiso para realizar esta acción"
                        );
                      } else {
                        this.grid.idGrid.deleteRecord(
                          this.selectedRow.selectedRow
                        );
                      }
                    });
                })
                .catch(function (error) {
                  this.$alert("Error " + error.message);
                });
            } else {
              this.$alert("Error: confirmación denegada");
            }
          },
        });
      }
    },

    editItem(a) {
      if (this.grid.idGrid.selectedCount == 0) {
        this.$alert("Debes selecionar un item para realizar esta acción");
      } else {
        this.$emit("toggle", a);
      }
    },

    copyRow() {
      if (this.grid.idGrid.selectedCount == 0) {
        this.$alert("Debes selecionar un item para realizar esta acción");
      } else {
        this.itemsSelected = [];
        var info = this.grid.idGrid.table;
        var ids = Object.values(this.grid.idGrid.selected);
        var record = this.grid.idGrid.recordBody.children;
        // console.log(ids)
        // console.log(record)
        for (let i = 0; i < info.length; i++) {
          const item = info[i];
          for (let j = 0; j < ids.length; j++) {
            const id = ids[j];
            if (item["$id"] == id) {
              this.itemsSelected.push(record[i]);
            }
          }
        }
        var el = document.createElement("textarea");
        for (let j = 0; j < this.itemsSelected.length; j++) {
          var tr = this.itemsSelected[j].children;
          for (let i = 0; i < tr.length; i++) {
            el.value = el.value + (tr[i].textContent + "\t");
          }
          el.value = el.value + "\n";
        }
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
      }
    },

    async export() {
      this.$emit("texto-tool", "Exportando...");
      var dt = new Date().now;
      var today = this.$moment(dt).format("YYYY/MM/DD");
      await this.exportDelay(
        this.exportBottom.nameDoc + today,
        this.grid.idGrid
      ).then((e) => {
        if (e == "OK") {
          this.$emit("texto-tool", "Exportado!");
        }
      });
    },

    async exportDelay(name, grid) {
      return new Promise(function (resolve) {
        setTimeout(function () {
          const format = "xlsx";
          const exportSelectedOnly = false;
          const filename = name;
          grid.exportTable(format, exportSelectedOnly, filename);
          resolve("OK");
        }, 1000);
      });
    },

    aplicarCol() {
      this.isShowColor = false;
      this.$emit("color-item", this.color.hexa);
    },

    eventosAddColumns(a) {
      if (a == "refresh") {
        this.$emit("toggle", "refresh");
      }
      this.addColsBottom.active = false;
    },

    eventosAddCols(d) {
      console.log(d);
      if (d == "close") {
        this.addNewColsAction = false;
      }
      if (d == "refresh") {
        this.addNewColsAction = false;
        this.$emit("toggle", "refresh");
      }
    },

    eventosDeleteCols(d) {
      if (d == "close") {
        this.deleteNewColsAction = false;
      }
      if (d == "refresh") {
        this.$emit("toggle", "refresh");
        this.deleteNewColsAction = false;
      }
    },

    eventosOrganiceCols(d) {
      if (d == "close") {
        this.openOrganice = false;
      }
      if (d == "refresh") {
        this.$emit("toggle", "refresh");
        this.openOrganice = false;
      }
    },

    eventosEditLabelCols(d) {
      if (d == "close") {
        this.openEditLabel = false;
      }
      if (d == "refresh") {
        this.$emit("toggle", "refresh");
        this.openEditLabel = false;
      }
    },
    eventosAssociate(d) {
      if (d == "close") {
        this.openAssociate = false;
      }
      if (d == "refresh") {
        this.$emit("associate-cols", "refresh");
        this.openAssociate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.centerC {
  text-align: center !important;
}
.contenedor-tools {
  min-width: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  //   padding: 5px;
  border: 1px #8884 solid;
  z-index: 10;
}

.contenedor-tools__icon {
  size: 10px !important;
  color: #999 !important;
}

.contenedor-tools__btn {
  margin: 0;
  text-transform: none;
  background: none !important;
}
.pdg {
  padding: 2px !important;
  min-width: 50px !important;
}

.lefticons {
  pointer-events: none;
  position: absolute !important;
  min-width: 100% !important;
  padding: 0 0 0 40px !important;
  // background-color: red !important;
}

.contenedor-tools__textoActionBar {
  margin: 0 30px 0 0 !important;
  padding: 0 10px !important;
  max-height: 45px;
  width: 10% !important;
  /* background-color: red; */
  overflow-y: auto;
}

.contenedor-tools__textoActionBar:hover {
  /* background-color: red; */
  overflow-y: auto;
}

.texto_action--Bar {
  color: rgb(233, 155, 126);
  font-weight: 400;
  font-size: 12px;
  text-align: right;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-text-field {
  max-width: 60px !important;
}
.rowHeigth {
  display: flex !important;
  min-height: auto !important;
}
</style>