<template>
  <v-card>
    <v-card-title>
      <span class="headline">Personalizar columnas</span>
    </v-card-title>
    <v-card-text>
      <v-container v-bind:style="{ 'text-align': 'right' }"> </v-container>
      <v-container>
        <v-autocomplete
          label="Edita columnas"
          v-model="columnasMostrar"
          :items="listaColumnas"
          item-text="nombre"
          item-value="nombre"
          item-id="valor"
          chips
          clearable
          deletable-chips
          multiple
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>Este nombre no existe</v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cancelar</v-btn>
      <v-btn color="primary" :disabled="isGuardando" text @click="saveColumns"
        >Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "EditCols",
  props: ["columnasMostrar", "listaColumnas", "tipo", "materia", "periodo"],

  data() {
    return {
      isGuardando: false,
    };
  },

  methods: {
    // refresh() {
    //   this.isGuardando = true;
    //   for (var i = 0; i < this.listaColumnas.length; i++) {
    //     this.listaColumnas[i]["valor"] = true;
    //   }
    //   // Descomentar esto
    //   firebase
    //     .auth()
    //     .currentUser.getIdToken(/* forceRefresh */ true)
    //     .then((idToken) => {
    //       const fd = new FormData();
    //       fd.append("token", idToken);
    //       fd.append("tipo", this.tipo);
    //       fd.append("restaurar", "SI");
    //       if (this.materia != null) {
    //         fd.append("materia", this.materia);
    //         fd.append("periodo", this.periodo);
    //       }
    //       fd.append("datos", JSON.stringify(this.listaColumnas)); // cambia pass profesor
    //       axios.post(host + "/celdas_ocultas", fd).then((result) => {
    //         let data = result.data;
    //         this.isGuardando = false;
    //         if (data.text == "OK") {
    //           this.$emit("edit-columns", true);
    //         } else {
    //           this.$alert(
    //             "No se pudo realizar la personalización de las columnas"
    //           );
    //         }
    //       });
    //     })
    //     .catch(function (error) {
    //       this.$alert("Error " + error.message);
    //     });
    // },
    close() {
      this.$emit("edit-columns", false);
    },
    saveColumns() {
      this.isGuardando = true;
      for (var k = 0; k < this.listaColumnas.length; k++) {
        this.listaColumnas[k]["valor"] = false;
      }
      for (var i = 0; i < this.listaColumnas.length; i++) {
        for (var j = 0; j < this.columnasMostrar.length; j++) {
          if (this.listaColumnas[i]["nombre"] == this.columnasMostrar[j]) {
            this.listaColumnas[i]["valor"] = true;
          }
        }
      }
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", this.tipo);
          if (this.materia != null) {
            fd.append("materia", this.materia);
            fd.append("periodo", this.periodo);
          }
          fd.append("datos", JSON.stringify(this.listaColumnas)); // cambia pass profesor
          axios.post(host + "/celdas_ocultas", fd).then((result) => {
            let data = result.data;
            this.isGuardando = false;
            if (data.text == "OK") {
              // this.mostrarTable = false;
              this.$emit("edit-columns", "edit");
            } else {
              this.$alert(
                "No se pudo realizar la personalización de las columnas"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
  },
};
</script>