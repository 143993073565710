<template>
  <v-card>
    <v-card-title>Nuevas columnas</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="cantidadCols"
              type="number"
              label="No. de columnas a agregar"
            ></v-text-field>
          </v-col>
          <v-col
            v-for="(data, index) in cantidadCols | number"
            :key="index"
            cols="12"
            sm="12"
            md="3"
          >
            <v-text-field
              v-model="nombresCols[index]"
              label="Nombre col: "
              required
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="$emit('add-newcols', 'close')"
        >Cerrar</v-btn
      >
      <v-btn color="primary" :disabled="isGuardando" text @click="newCol"
        >Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
export default {
  name: "ToolBarNavigationAddCols",
  props: {
    materia: String,
    periodo: String,
  },
  data() {
    return {
      cantidadCols: 0,
      nombresCols: [],
      isGuardando: false,
    };
  },
  methods: {
    newCol() {
      var si = false;
      for (let f = 0; f < this.cantidadCols.length; f++) {
        if (this.nombresCols[f] == null || this.nombresCols[f] == "") {
          si = false;
        } else {
          si = true;
        }
      }
      if (si == true) {
        this.isGuardando = true;
        this.jsondata = [];
        this.newData = [];
        this.mostrarTable = false;
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "editar_estructura");
            fd.append("subtipo", "agregar_item");
            fd.append("materia", this.materia);
            fd.append("periodo", this.periodo);
            fd.append("nombres", this.nombresCols);
            // cambia pass profesor
            axios.post(host + "/cursos", fd).then((result) => {
              let data = result.data;
              this.isGuardando = false;
              if (data.text == "OK") {
                this.$emit("add-newcols", "refresh");
              } else {
                this.$alert(
                  "No se pudo agregar esta columna nueva " + data.text
                );
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      } else {
        this.$alert("No puedes enviar columnas sin nombre");
      }
    },
  },
};
</script>

<style>
</style>