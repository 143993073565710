<template>
  <v-card>
    <v-card-title>
      <span class="headline">Reorganizar columnas</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-col>
          <span
            >* Ajustar order de las columnas moviendo cada item a la posición
            deseada</span
          >
        </v-col>
        <draggable
          class="list-group"
          ghost-class="ghost"
          v-model="columnasOrganizar"
          @start="drag = true"
          @end="drag = false"
        >
          <v-list-item
            dense
            v-for="(element, i) in columnasOrganizar"
            :key="element[i]"
          >
            <v-list-item-icon>
              <v-icon> mdi-unfold-more-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <h3>
                {{ element }}
              </h3>
            </v-list-item-content>
          </v-list-item>
        </draggable>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cerrar</v-btn>
      <v-btn color="primary" :disabled="isGuardando" text @click="saveColumns"
        >Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  name: "ToolBarOrganiceCols",
  props: ["listaColumnas", "materia", "periodo", "data"],
  components: {
    draggable,
  },
  data() {
    return {
      columnasOrganizar: [],
      alerta: false,
      isGuardando: false,
    };
  },
  mounted() {
    for (var k = 0; k < this.listaColumnas.length; k++) {
      this.columnasOrganizar.push(this.listaColumnas[k]);
    }
  },
  methods: {
    saveColumns() {
      this.isGuardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cambiarOrden");
          fd.append("materia", this.materia);
          fd.append("periodo", this.periodo);
          fd.append("listaReordenada", this.columnasOrganizar.toString()); // cambia pass profesor
          axios.post(host + "/editaColumna", fd).then((result) => {
            let data = result.data;
            this.isGuardando = false;
            if (data.text == "OK") {
              this.$emit("organice-cols", "refresh");
            } else {
              this.$alert(
                "No se pudo realizar la reorganización de las columnas"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    close() {
      this.$emit("organice-cols", "close");
    },
  },
};
</script>
  
  <style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>