<template>
  <v-card>
    <v-card-actions class="top">
      <h4 color="primary">Historial de cambios</h4>
      <v-spacer></v-spacer>
      <v-btn v-show="itemHis > 0" plain icon @click="minus">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn v-show="itemHis < dataHis.length - 1" plain icon @click="add">
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text v-if="dataHis.length != 0">
      <b>{{ dataHis[itemHis]["nombreUsuario"] }}</b>
      <p>
        fecha: {{ dataHis[itemHis]["fecha"] | moment("YYYY/MM/DD - HH:mm:ss") }}
      </p>
      <b>Se reemplazó: {{ dataHis[itemHis]["valor"] }} </b>
    </v-card-text>
    <v-card-text v-else>
      <p>No existe historial</p>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  props: {
    historial: {
      required: true,
      id: null,
      nombreCol: null,
    },
  },
  data() {
    return {
      dataHis: null,
      itemHis: 0,
    };
  },
  created() {
    console.log(this.historial);
    var data = document.title;
    // if (document.title.includes('/')) {
    //     var srt = document.title.split('/')
    //     data = srt[0]
    // }
    // else {
    //     data = document.title
    // }
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const fd = new FormData();
        fd.append("token", idToken);
        fd.append("tipo", "consultar");
        fd.append("nombreTabla", data);
        fd.append("nombreCol", this.historial.nombreCol);
        fd.append("id", this.historial.id);
        axios.post(host + "/historialCelda", fd).then((rta) => {
          console.log(rta.data);
          this.dataHis = rta.data.lista;
        });
      })
      .catch(function (error) {
        this.$alert("Error " + error.message);
      });
  },
  methods: {
    add() {
      if (this.itemHis < this.dataHis.length) this.itemHis++;
    },
    minus() {
      if (this.itemHis > 0) this.itemHis--;
      console.log(this.itemHis);
    },
  },
};
</script>