<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cambiar nombres a las columnas</span>
    </v-card-title>
    <v-card-subtitle>
      <span
        >* Para guardar cada una de las ediciones debes darle click en el icono
        que aparece en la parte derecha (chulo)</span
      >
    </v-card-subtitle>
    <v-card-text>
      <v-container>
        <!-- <v-col>
          <span>* Ajustar nombre de las columnas</span>
        </v-col> -->
        <div dense v-for="element in columnasOrganizar" :key="element">
          <v-text-field
            append-icon="mdi-check"
            @click:append="saveColumns(element)"
            v-model="element.nombre"
            label="Nombre de la columna"
          >
          </v-text-field>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>
    
    <script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "ToolBarNavEditLabel",
  props: ["listaColumnas", "materia", "periodo", "data"],
  components: {},
  data() {
    return {
      columnasOrganizar: [],
      alerta: false,
      new: "",
    };
  },
  mounted() {
    for (var k = 0; k < this.listaColumnas.length; k++) {
      this.columnasOrganizar.push({
        nombre: this.listaColumnas[k],
        text: this.listaColumnas[k],
      });
    }
  },
  methods: {
    saveColumns(element) {
      console.log(element);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cambiarNombre");
          fd.append("materia", this.materia);
          fd.append("periodo", this.periodo);
          fd.append("nombreAnterior", element.text); // cambia pass profesor
          fd.append("nuevoNombre", element.nombre); // cambia pass profesor
          axios.post(host + "/editaColumna", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              this.$emit("edit-label", "refresh");
            } else {
              this.$alert(
                "No se pudo realizar el cambio de nombre en las columnas"
              );
            }
          });
          this.new = "";
        })
        .catch(function (error) {
          this.new = "";
          this.$alert("Error " + error.message);
        });
    },
    close() {
      this.$emit("edit-label", "close");
    },
  },
};
</script>
    
    <style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>