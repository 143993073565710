<template>
  <v-card>
    <v-card-title>
      <span class="headline">Eliminar columnas</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-list>
          <v-list-item v-for="(item, i) in columnasEliminar" :key="i">
            <v-list-item-title>
              <v-row>
                <v-col>
                  <span>{{ item.nombre }}</span>
                </v-col>
                <v-col>
                  <v-switch
                    class="rowNop"
                    color="primary"
                    v-model="item.valor"
                    @change="validate(item)"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cerrar</v-btn>
      <v-btn color="primary" :disabled="isGuardando" text @click="saveColumns"
        >Eliminar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "ToolBarNavigationDeleteCols",
  props: ["listaColumnas", "materia", "periodo", "data"],

  data() {
    return {
      columnasEliminar: [],
      alerta: false,
      isGuardando: false,
    };
  },
  mounted() {
    for (var k = 0; k < this.listaColumnas.length; k++) {
      this.columnasEliminar.push({
        nombre: this.listaColumnas[k],
        valor: false,
      });
    }
  },
  methods: {
    refresh() {
      this.columnasEliminar = [];
      for (var k = 0; k < this.listaColumnas.length; k++) {
        this.columnasEliminar.push({
          nombre: this.listaColumnas[k],
          valor: false,
        });
      }
    },
    validate(inf) {
      if (inf.valor == true) {
        this.data.forEach((element) => {
          // console.log(element)
          if (element[inf.nombre] != "") {
            this.alerta = true;
          }
        });
      } else {
        this.alerta = false;
      }
      if (this.alerta) {
        this.$alert("Algunas columnas tienen información guardada");
        this.refresh();
      }
    },
    saveColumns() {
      this.isGuardando = true;
      this.$confirm({
        message: `Estas seguro que deseas eliminar las columnas `,
        button: {
          no: "Cerrar",
          yes: "Aceptar",
        },
        callback: (confirm) => {
          if (confirm == true) {
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const fd = new FormData();
                fd.append("token", idToken);
                fd.append("tipo", "eliminar_columnas");
                fd.append("materia", this.materia);
                fd.append("periodo", this.periodo);
                fd.append("columnas", JSON.stringify(this.columnasEliminar)); // cambia pass profesor
                axios.post(host + "/cursos", fd).then((result) => {
                  let data = result.data;
                  this.isGuardando = false;
                  if (data.text == "OK") {
                    this.$emit("delete-newcols", "refresh");
                  } else {
                    this.$alert(
                      "No se pudo realizar la eliminación de las columnas"
                    );
                  }
                });
              })
              .catch(function (error) {
                this.$alert("Error " + error.message);
              });
          }
        },
      });
    },
    close() {
      this.$emit("delete-newcols", "close");
    },
  },
};
</script>

<style>
</style>